<template>
  <div>
    <Navigation />
    <slot />
    <Footer />

    <KeepAlive>
      <VideoPopup />
    </KeepAlive>
  </div>
</template>


