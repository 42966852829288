<template>
  <div>
    <Navigation />
    <slot />
    <Footer />

    <KeepAlive>
      <VideoPopup />
    </KeepAlive>

    <ClientOnly>
      <CookieModal v-if="showCookieModal"
                   @set-consent="setCookieConsent"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from '#imports';
import { isLocalStorageAvailable } from '~/utils/local-storage';
import { useWonderpush } from '~/composables/use-wonderpush';

const { setUserConsent: setWonderpushConsent, subscribeToNotifications } = useWonderpush();

const showCookieModal = computed(() => {
  if (!process.client || !isLocalStorageAvailable()) return;
  const consent = localStorage.getItem('consent');
  // consent === 'true' check is for legacy purposes to reopen notice for clients
  // that used the website before the Wonderpush consent update on 21 June 2024.
  return !consent || consent === 'true';
});

function setCookieConsent(value: boolean) {
  if (!process.client || !isLocalStorageAvailable()) return;
  value === true ? localStorage.setItem('consent', 'yes') : localStorage.setItem('consent', 'no');
}

onMounted(() => {
  if (process.client && isLocalStorageAvailable() && localStorage.getItem('consent') === 'yes') {
    setWonderpushConsent(true);
    subscribeToNotifications();
  }
});
</script>